import React from "react";
import { Layout } from "antd";
const { Content } = Layout;

class AboutContentPage extends React.Component {
  state = {};
  render() {
    return (
      <Content>
        <div className="content">
          <h1>DayViewer Information</h1>
          <p>
            Please refer to the links in the side panel to find the
            documentation regarding DayViewer Calendar Planner system
          </p>
          <p>
            DayViewer Ltd is a Registered Company in England &amp; Wales
            Registration number 7115342
          </p>
          <div style={{ margin: 50 }}>
            <img
              src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dv-productivity-improvement.png"
              width="90%"
            />
          </div>
        </div>
      </Content>
    );
  }
}

export default AboutContentPage;
