import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";

import Header from "../components/common/header";
import CommonFooter from "../components/common/footer";
import Content from "../components/about/content";
import AboutSider from "../components/about/about-sider";

export default ({ data }) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>DayViewer Online Planner - About</title>
      <meta
        name="description"
        content="DayViewer is an online calendar planner and organizer. It can be used as an office or business diary system with the team room task collaboration system. Sign up today and see how much more organized you will be"
      />
    </Helmet>
    <Layout>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <AboutSider />
        <Content />
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);
